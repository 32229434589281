import { useContext } from "react";
import { TextsContext } from "../context/textsContext";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Picture from "./PictureComponent";
import StyleTxt from "../scss/text.module.scss";
import StyleFooter from "../scss/footer.module.scss";
import { Icon } from "@iconify/react";
import { ParagraphComponent } from "./ParagraphComponent";
export function FooterComponent() {
  const {
    texts
  } = useContext(TextsContext);
  return <section>
			<footer>
				<Navbar expand="lg" className="bg-gris">
					<Container>
						<Nav className={`mx-auto mx-md-0 me-md-auto d-flex justify-content-sm-center justify-content-md-start`}>
							<Navbar.Brand href="#" className={`  ${StyleFooter.logo_iridian} `}>
								<Picture src="/img/logo_iridian.png" alt="joao" styleGroup="w-100" />
							</Navbar.Brand>
						</Nav>

						<Nav className="mx-auto mx-md-auto justify-content-center justify-content-md-between">
							<div className={`d-sm-flex justify-content-center align-items-center`}>
								<div className={`  ${StyleFooter.logo} `}>
									<Picture src="/img/logo.png" alt="joao" />
								</div>
								<div className="d-flex flex-column ms-2 text-center text-md-start">
									<ParagraphComponent styleGroup={`text-uppercase montserrat_regular color-white mb-0 ${StyleTxt.p}`}>
										&#169; {texts?.footer_phrase ?? 'Joao Díaz Todos los derechos reservados.'} 
									</ParagraphComponent>
									<ParagraphComponent styleGroup={`text-uppercase montserrat_regular_700 color-white text-decoration-underline mb-0 ${StyleTxt.p}`}>
										{texts?.footer_contact ?? 'CONTACTO: JOAO@CORREO.COM'} 
									</ParagraphComponent>
								</div>
							</div>
						</Nav>

						<Nav className={`mx-0 ms-md-auto ${StyleFooter.arrowUp}`}>
							<div className={`w-100 d-flex justify-content-center justify-content-md-end`}>
								<Nav.Link href="#action1" className={`p-0`}>
									<Icon icon="teenyicons:arrow-up-outline" className={`color-white ${StyleFooter.arrowUp}`} />
								</Nav.Link>
							</div>
						</Nav>
					</Container>
				</Navbar>
			</footer>
		</section>;
}