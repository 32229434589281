import React, { createContext, useState } from 'react';
import { getTexts } from '../api/textsApi';
export const TextsContext = createContext();
export const TextsProvider = ({
  children
}) => {
  const [texts, setTexts] = useState([]);
  const updateTexts = locale => {
    getTexts(locale).then(response => {
      setTexts(response);
      localStorage.setItem('locale', locale);
      console.log('first', response);
    }).catch(e => {
      console.error(e);
    });
  };
  const verifyCurrentLanguage = () => {
    if (localStorage.getItem('locale') && texts.length === 0) {
      updateTexts(localStorage.getItem('locale'));
    }
  };
  return <TextsContext.Provider value={{
    texts,
    updateTexts,
    verifyCurrentLanguage
  }}>{children}</TextsContext.Provider>;
};