import 'bootstrap/dist/css/bootstrap.min.css';
import '../src/scss/generalClass.scss';
import '../src/scss/fonts.scss';
import React, { Fragment, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { TextsProvider } from './context/textsContext';
import { HeaderComponent } from "./components/HeaderComponent";
import { FooterComponent } from "./components/FooterComponent";
import Loader from './components/LoaderComponent';

const LazyBasePage = React.lazy(() => import('./pages/Home/BasePage'));
const LazyHomePage = React.lazy(() => import('./pages/Home/HomePage'));

function App() {
	return (
		<Fragment>
			<BrowserRouter>
				<TextsProvider>
					<HeaderComponent />
					<main>
						<Suspense fallback={<Loader />}>
							<Routes>
								<Route path="/base" element={<LazyBasePage />} />
								<Route path="/" element={<LazyHomePage />} />
							</Routes>
						</Suspense>
					</main>
					<FooterComponent />
				</TextsProvider>
			</BrowserRouter>
		</Fragment>
	);
}

export default App;
