import React, { Fragment } from "react";
import load from "../scss/loader.module.scss";
const Loader = () => {
  return <Fragment>
			<div className="d-flex justify-content-center align-items-center">
				<div className={`d-flex justify-content-center align-items-center`}>
					<div className={`${load.loaderSpin} ${load.top}`}></div>
					<div className={`${load.loaderSpin} ${load.middle}`}></div>
					<div className={`${load.loaderSpin} ${load.center}`}></div>
					<span className="color-amarillo">Loading ...</span>
				</div>
			</div>
		</Fragment>;
};
export default Loader;