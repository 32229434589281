import axios from 'axios'
import { getEnvVariables } from '../helpers/getEnvVariables'

const { API_URL } = getEnvVariables()

const mainApi = axios.create({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': '*',
    'Access-Control-Allow-Headers': 'Content-Type',
    baseURL: API_URL,
    withCredentials: true,
})

mainApi.interceptors.request.use((config) => {
    config.headers = {
        ...config.headers,
    }
    return config
})

export default mainApi
