import { useContext, useEffect, useState } from "react";
import { TextsContext } from "../context/textsContext";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import StyleHeader from "../scss/header.module.scss";
import StyleTxt from "../scss/text.module.scss";
import Picture from "./PictureComponent";
import { Icon } from "@iconify/react";
import SEO from "./SEO";
export function HeaderComponent() {
  const {
    texts,
    updateTexts
  } = useContext(TextsContext);
  const [language, setLanguage] = useState(false);
  const LanguageState = () => {
    setLanguage(!language);
    language === false ? updateTexts('es') : updateTexts('en');
  };
  useEffect(() => {
    updateTexts('en');
  }, []);
  return <header className={`${StyleHeader.header}`}>
			<SEO title="JOAO Carreras de Formula 4" description="¡Descubre emocionantes carreras de Fórmula 4 en joao.iridian.co!" url="https://joao.iridian.co" />
			<Navbar collapseOnSelect expand="lg" className="justify-content-end bg-negro opacity-25" fixed="top">
				<Container>
					<Navbar.Brand href="#" className={StyleHeader.logo}>
						<Picture src="/img/logo.png" alt="joao" styleGroup="w-100" />
					</Navbar.Brand>
					<Navbar.Toggle aria-controls="responsive-navbar-nav border-0" className="custom-toggler" />
					<Navbar.Collapse id="responsive-navbar-nav color-white">
						<Nav className="ms-auto my-2 my-lg-0"
          // style={{ maxHeight: "100px" }}
          navbarScroll>
							<Nav.Link href="#" className={`me-5 my-auto cursorPointer ${StyleTxt.specialText} ${StyleHeader.hoverText} montserrat_black_italic color-white  me-3`} onClick={LanguageState}>
								{'ES / EN'}
							</Nav.Link>
							<Nav.Link href="#action1" className={`${StyleTxt.specialText} ${StyleHeader.hoverText} montserrat_black_italic color-white  me-3`}>
								{texts?.about_joao_name_title ?? 'SOBRE JOAO'}
							</Nav.Link>
							<Nav.Link href="#action2" className={`${StyleTxt.specialText} ${StyleHeader.hoverText} montserrat_black_italic color-white  me-3`}>
								{texts?.schedule_title ?? 'CRONOGRAMA'}
							</Nav.Link>
							<Nav.Link href="#action3" className={`${StyleTxt.specialText} ${StyleHeader.hoverText} montserrat_black_italic color-white  me-3`}>
								{texts?.sponsors_title ?? 'PATROCINADORES'}
							</Nav.Link>
							<Nav.Link href="#action4" className={`${StyleTxt.specialText} ${StyleHeader.hoverText} montserrat_black_italic color-white  me-3`}>
								{texts?.gallery_title ?? 'GALERÍA'}
							</Nav.Link>
							<Nav.Link href="https://www.instagram.com/joaodiazofficial/" target="_blank" className={`  `}>
								<Icon icon="ri:instagram-fill" className={`${StyleTxt.iconFz} ${StyleHeader.hoverIco} montserrat_black_italic color-white `} />
							</Nav.Link>
							<Nav.Link href="https://www.facebook.com/profile.php?id=100085314271791&mibextid=MKOS29" target="_blank" className={` `}>
								<Icon icon="bi:facebook" className={`${StyleTxt.iconFz} ${StyleHeader.hoverIco} montserrat_black_italic color-white `} />
							</Nav.Link>
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
		</header>;
}