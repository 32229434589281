// src/components/SEO.js

import React from 'react';
import { Helmet } from 'react-helmet';

const SEO = ({ title, description, url }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <link rel="canonical" href={url} />
      <meta property="og:title" content="Open Graph: JOAO F4" />
      <meta property="og:type" content="FÓRMULA 4 NUEVA GENERACIÓN en el Gálvez - Una GRAN OPCIÓN para Pilotos de Karting?" />
      <meta property="og:image" content="https://joao.iridian.co/img/cronograma/joao.png?format=webp" />
      <meta property="og:url" content="https://joao.iridian.co/"/>
    </Helmet>
  );
};

export default SEO;